export const  OrderNet = (
    {size=25 , color="#fff", ...props }
) => {
return <svg
    width={size}
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
     {...props}
>
    <path
        d="M13.6 10V3L4.59998 14H11.6V21L20.6 10H13.6Z"
        stroke="#0000FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
</svg>
}

