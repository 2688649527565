import {Typography, Divider } from "antd"

const { Text } = Typography;
export const ActivityCard = ({text ,value}) =>{
    return (
				<div className='other-component'>
					<p className='other-title'>{text}</p>
					<Text className='other-value'>{value}</Text>
				</div>
		);
}