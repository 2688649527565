import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AppHeader from "./components/appHeader";
import "./App.less";
import Login from "./components/globalComponents/login";
import { useApp } from "./store";
import DailyTable from "./components/globalComponents/dailyTable";
import HourlyTable from "./components/globalComponents/hourlyTable";
import UserActivity from "./components/user-activity/user-activity";
import { QueryClientProvider } from "react-query";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Layout } from "antd";
import dayjs from "dayjs";
import { refresh,queryClient } from "./api";

function App() {
    const {isLogedIn, setIslogedIn, setAppLoading, setUser} = useApp();
    let location = useLocation();

    useEffect(() => {
        const bo_token = localStorage.getItem("bo_token");
        const bo_user = localStorage.getItem("bo_user");
        if (bo_token && bo_user) {
            setIslogedIn(true);
            setUser(JSON.parse(bo_user));
        }
      }, []);

      const loagout = () => {
        setAppLoading(false);
        setIslogedIn(false);
        setUser(null);
        localStorage.clear();
      };

      const refreshToken = async () => {
        setAppLoading(true);
        refresh(async (resp, err) => {
          if (err) return loagout();
          if (resp.success) {
            setAppLoading(false);
            localStorage.setItem("bo_token", resp.data.token);
            localStorage.setItem("bo_refresh_token", resp.data.refreshToken);
          } else {
            loagout();
          }
        });
      };

      useEffect(() => {
        const token = localStorage.getItem("bo_token");
        if (token) {
          var decoded = jwt_decode(token);
          if (!dayjs().isAfter(dayjs(decoded.exp))) {
            refreshToken();
          } else {
            setAppLoading(false);
          }
        } else {
          loagout();
        }
      }, [location]);


    return !isLogedIn ? (
        <Layout style={{height:"100vh" }}>
            <Login />
        </Layout>
    ) : (
        <>
            <AppHeader />
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route exact path="/login" element={<Login />} />

                    <Route
                     path="/"
                     exact
                     element={
                        <PrivateRoute
                          Component={UserActivity}
                          auth={isLogedIn}
                        />
                      }
                    />
                    <Route
                     path="/daily"
                     element={
                        <PrivateRoute
                          Component={DailyTable}
                          auth={isLogedIn}
                        />
                      }
                    />
                    <Route
                     path="/hourly"
                     element={
                        <PrivateRoute
                          Component={HourlyTable}
                          auth={isLogedIn}
                        />
                      }
                    />
                </Routes>
            </QueryClientProvider>
        </>
    );
}

const PrivateRoute = ({ Component, auth }) => {
    return auth ? <Component /> : <Navigate to="/login" />;
  };

export default App;
