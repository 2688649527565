import "../../user-activity/style.less";
import { Typography , DatePicker  } from "antd";
import moment from "moment";
import {fromDateFormated, getOrdersHourly, toDateFormated} from "../../../api";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export const Time = ({from , to, setFrom , setTo , hasTitle , showTime}) => {

    const { refetch } = useQuery(["salesHourly", from ,to] , ({ queryKey }) => getOrdersHourly(queryKey[1], queryKey[2]),
      {
          retry: 1,
      }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const objToQueryString = (obj) => {
	Object.keys(obj).forEach((key) => {
	  if (!obj[key]) {
		delete obj[key];
	  }
	});

	let _queryString = Object.keys(obj)
	  .map((key) => key + "=" + obj[key])
	  .join("&");

	return _queryString;
  };


      const makeFilter = ({ ...obj }, isInit = true) => {
        let queryObj = {
          ...obj,
        };

        if(isInit) return setSearchParams(objToQueryString(queryObj));
      };


      useEffect(() => {
		let obj = {
            from: searchParams.get("from") || null,
            to: searchParams.get("to") || null,
          };

          makeFilter(obj, false);
          setFrom(obj.from);
          setTo(obj.to);
      }, [
        searchParams.get("from"),
        searchParams.get("to"),
      ]);


    const handleOnChange = (value, dateString) => {
            if (value) {
                let from = dateString[0];
                let to = dateString[1];
                setFrom(from);
                setTo(to);
				makeFilter({ from, to, page_num: 1 });
                refetch();
            } else {
                setFrom(null);
                setTo(null);
				makeFilter({ page_num: 1  });
            }
        };

    return (
			<div className='time-container'>
				{hasTitle ? <Text className='arabic-title'>Date time range</Text> : null}
				<RangePicker
					style={{ borderRadius: "8px" }}
					showTime={showTime}
					value={
						from
							? [moment(from), moment(to)]
							: [moment(fromDateFormated), moment(toDateFormated)]
					}
					defaultPickerValue={[
						moment(fromDateFormated),
						moment(toDateFormated),
					]}
					onChange={handleOnChange}
					format='YYYY-MM-DD HH:mm'
				/>
			</div>
		);
};
