import { useEffect } from "react";
import OrdersActivity from "./order-activity";
import {
	OrderActivityIcon,
	OrderNet,
	BuildingIcon,
	ShoppingIcon,
	CurrencyDollar,
	Inbox,
	Chart, Android,
	Ios,
} from "../Bo-Icons";
import { Col, Divider, Row, Space } from "antd";
import { getTotalOrders, queryClient } from "../../api";
import { useQuery } from "react-query";
import {
	ActivityContainer,
	ActivityCard,
	Time,
} from "../globalComponents/activity";
import { useReports, useApp } from "../../store";
import Map from "./Map";


export function formatNum(num) {
	return new Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "IQD",
	}).format(num);
}

const UserActivity = (props) => {
	const { globalData, setGlobalData, from, to, setTo, setFrom } = useReports();
	const { setIslogedIn } = useApp();
	const content = [
		{
			text: "gross Orders",
			value: globalData?.gross_orders,
			icon: <OrderActivityIcon {...props} />,
			textColor: "#CCCCFF",
			customColor: "white",
			wrapperBgColor: "#6666FF",
			bgColor: "#0000ff",
		},
		{
			text: "Net. Orders",
			value: globalData?.net_orders,
			icon: <OrderNet />,
			textColor: "grey",
			customColor: "#0000ff",
			wrapperBgColor: "#D6D8F9",
			bgColor: "#f3f5f8",
		},
		{
			text: "External Orders",
			value: globalData?.external_orders,
			icon: <ShoppingIcon />,
			textColor: "grey",
			customColor: "#00A857",
			wrapperBgColor: "#D6ECE5",
			bgColor: "#f3f5f8",
		},
		{
			text: "Internal Orders",
			value: globalData?.internal_orders,
			icon: <BuildingIcon />,
			textColor: "grey",
			customColor: "#CC6E00",
			wrapperBgColor: "#EEE5DA",
			bgColor: "#f3f5f8",
		},
	];

	const { data } = useQuery(
		["salesTotal", from, to],
		({ queryKey }) => getTotalOrders(queryKey[1], queryKey[2]),
		{
			retry: 1,
			refetchInterval: 30000,
		}
	);

	useEffect(() => {
		if (data && data?.data && data?.data?.OrdersLocations) {
			let features = data?.data?.OrdersLocations?.map(o => ({
				type: "Feature",
				properties: {
					id: o.id,
					mag: 1,
				},
				geometry: {
					type: "Point",
					coordinates: [
						o.clientLon,
						o.clientLat,
					]
				}

			}))
			delete data.data.OrdersLocations
			setGlobalData({ ...data?.data, OrdersLocations: { type: "FeatureCollection",features } });
		}
		if (data && data.err === String("unauthorized")) {
			localStorage.clear();
			queryClient.clear()
			setIslogedIn(false);
		}
	}, [data]);



	const platform = [
		{
			text: "IOS",
			value: globalData?.ios,
		},
		{
			text: "Android",
			value: globalData?.android,
		},
		{
			text: "Others",
			value: globalData?.other,
		},
	];
	const costList = [
		{
			text: "NMV",
			value:
				globalData?.nmv !== null ? formatNum(globalData?.nmv) : <small> no NMV</small>,
		},
		{
			text: "Baly Commission",
			value:
				globalData?.baly_comission !== null ? formatNum(globalData?.baly_comission)
					: (
						<small> no commission</small>
					),
		},
		{
			text: "Fully Paid",
			value: globalData?.fully_paid,
		},
	];

	const orderType = [
		{
			text: "Organic Orders",
			value: globalData?.organic_orders,
		},
		{
			text: "First Orders",
			value: globalData?.first_order,
		},
		{
			text: "Acquire Cost",
			value:
				globalData?.acquired_cost !== null ? (
					formatNum(globalData?.acquired_cost)
				) : (
					<small> no acquire cost </small>
				),
		},
	];

	const analyizes = [
		{
			text: "Voucher Spending .IQD",
			value:
				globalData?.voucher_spending !== null ? (
					formatNum(globalData?.voucher_spending)
				) : (
					<small> no voucher spending</small>
				),
		},
		{ text: "Orders With Vouchers", value: globalData?.orders_with_vouchers },
		{ text: "Orders With Discounts", value: globalData?.orders_with_discounts },
		{
			text: "Discount Spending IQD",
			value:
				globalData?.discount_share !== null ? (
					formatNum(globalData?.discount_share)
				) : (
					<small> no discount share</small>
				),
		},
	];

	return (
		<div style={{ margin: "6px 8px" }}>
			<Row
				gutter={[
					6,
					{
						xs: 12,
						sm: 16,
						md: 24,
						lg: 32,
					},
				]}>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 24 }}
					lg={{ span: 6 }}>
					<ActivityContainer
						icon={
							<div className='other-wrapper'>
								<CurrencyDollar />
							</div>
						}
						list={costList.map((el, index) =>
							index === 1 ? (
								<span key={index}>
									<ActivityCard text={el.text} value={el.value} key={index} />
									<Divider style={{ margin: "4px 0px" }} />
								</span>
							) : (
								<ActivityCard text={el.text} value={el.value} key={index} />
							)
						)}
						style={{ marginTop: "10px" }}
					/>
					<ActivityContainer
						icon={
							<div className='other-wrapper'>
								<Inbox />
							</div>
						}
						list={orderType.map((el, index) =>
							index === 1 ? (
								<span key={index}>
									<ActivityCard text={el.text} value={el.value} key={index} />
									<Divider style={{ margin: "4px 0px" }} />
								</span>
							) : (
								<ActivityCard text={el.text} value={el.value} key={index} />
							)
						)}
						style={{ marginTop: "8px" }}
					/>
				</Col>

				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 24 }}
					lg={{ span: 18 }}>
					<Row
						gutter={[
							8,
							{
								xs: 12,
								sm: 16,
								md: 12,
								lg: 32,
							},
						]}>
						{content.map((item, index) => {
							return (
								<Col
									key={index}
									xs={{ span: 12 }}
									sm={{ span: 12 }}
									md={{ span: 10 }}
									lg={{ span: 4.5 }}
									flex={1}>
									<OrdersActivity
										text={item.text}
										value={item.value}
										icon={item.icon}
										textColor={item.textColor}
										customColor={item.customColor}
										wrapperBgColor={item.wrapperBgColor}
										bgColor={item.bgColor}
										key={index}
									/>
								</Col>
							);
						})}
					</Row>
					<Divider style={{ margin: "8px 0px" }} />
					<Row gutter={[8, 24]}>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							md={{ span: 12 }}
							lg={{ span: 8 }}>
							<ActivityContainer
								icon={
									<div className='other-wrapper'>
										<Chart />
									</div>
								}
								list={analyizes.map((el, index) =>
									index === 1 ? (
										<span key={index}>
											<ActivityCard
												text={el.text}
												value={el.value}
											/>
											<Divider style={{ margin: "4px 0px" }} />
										</span>
									) : (
										<ActivityCard text={el.text} value={el.value} key={index} />
									)
								)}
							/>
							<Time
								from={from}
								to={to}
								setFrom={setFrom}
								setTo={setTo}
								hasTitle={true}
								showTime={{
									format: "HH:mm",
								}}
							/>
						</Col>

						<Col
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							md={{ span: 12 }}
							lg={{ span: 16 }}>
							<div className='map-container'>
								<Map orders={globalData?.OrdersLocations} />
							</div>
							<div style={{ width: "70%" }}>
								<ActivityContainer
									icon={
										<Space>
											<div className='other-wrapper'>
												<Ios />
											</div>
											<div className='other-wrapper'>
												<Android />
											</div>
										</Space>
									}
									list={
										<div className='flex-container'>
											{platform.map((el, index) => (
												<ActivityCard
													text={el.text}
													value={el.value}
													style={{ hieght: "100%" }}
													key={index}
												/>
											))}
										</div>
									}
									style={{ marginTop: "6px" }}
								/>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default UserActivity;
