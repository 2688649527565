import moment from "moment";
import {QueryClient} from 'react-query'
const LIVE_URL = "/api";
export const pageSize = 10;
const date = new Date();
// last night
export const fromDateFormated = moment(date.setHours(0, 0, 0, 0)).format(
    "YYYY-MM-DD HH:mm"
);
// next night
export const toDateFormated = moment(date.setHours(23, 59, 59, 0)).format(
    "YYYY-MM-DD HH:mm"
);

export const queryClient = new QueryClient();

export const login = ({ username, password }, cb) => {
    fetch(`${LIVE_URL}/auth/login`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
            username,
            password,
        }),
    })
        .then((res) => res.json())
        .then((jsonResults) => cb(jsonResults, null))
        .catch((e) => cb(null, e));
};

export const refresh = (cb) => {
    try {
      let bo_refresh_token = localStorage.getItem("bo_refresh_token");
      if (bo_refresh_token) {
        fetch(`${URL}/../auth/refresh`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bo_refresh_token}`,
          },
        })
          .then((res) => res.json())
          .then((resp) => {
            cb(resp, null);
          })
          .catch((e) => cb(null, e));
      }
    } catch (err) {
      cb(null, err);
    }
  };

// Sales Reports Functions
export const getOrdersHourly = async (from, to , page) => {

    let token = localStorage.getItem("bo_token");
    const res = await fetch(
        `${LIVE_URL}/v1/salesreport/hourly?&page_size=${pageSize}&page_num=${page || 1
        }&from=${from ? moment(from).subtract(3,'hour').format("YYYY-MM-DD HH:mm") : (from = fromDateFormated)}&to=${
            to ? moment(to).subtract(3,'hour').format("YYYY-MM-DD HH:mm") : (to = toDateFormated)
        }`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    ).then((res) => res.json());
    return res;
};

export const getTotalOrders = async (from, to) => {
	let token = localStorage.getItem("bo_token");
	const res = await fetch(
		`${LIVE_URL}/v1/salesreport/total?&from=${
        from ? moment(from).subtract(3, 'hour').format("YYYY-MM-DD HH:mm") : (from = fromDateFormated)
        }&to=${to ? moment(to).subtract(3, 'hour').format("YYYY-MM-DD HH:mm") : (to = toDateFormated)}`,
		{
			method: "GET",
			mode: "cors",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	).then((res) => res.json());
	return res;
};

export const getOrdersDaily = async (from, to, page) => {
    let token = localStorage.getItem("bo_token");
    const res = await fetch(
        `${LIVE_URL}/v1/salesreport/daily?&page_size=${pageSize}&page_num=${page || 1
        }&from=${from ? moment(from).subtract(3, 'hour').format("YYYY-MM-DD HH:mm"): (from = fromDateFormated)
        }&to=${to ? moment(to).subtract(3, 'hour').format("YYYY-MM-DD HH:mm") : (to = toDateFormated)}`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    ).then((res) => res.json());
    return res;
};
