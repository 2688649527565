import create from "zustand";

export const useApp = create((set) => ({
    // USER STATE
    user: null,
    username: "",
    password: "",
    isLoading: true,
    isLogedIn: false,
    appLoading: true,
    setIslogedIn: (isLogedIn) => set({ isLogedIn }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setUserName: (userName) => set({ userName }),
    setPassword: (password) => set({ password }),
    setUser: (user) => set({ user }),
    setAppLoading: (appLoading) => set({ appLoading }),

    // MAP STATES
    lat: null,
    lon: null,
    setLat: (lat) => set({lat}),
    setLon: (lon)=> set({lon}),
}));


export const useReports = create((set) => ({
    queryKey: "",
    globalData: null,
    setQueryKey: (queryKey) => set({ queryKey }),
    from: "",
    to: "",
    setFrom: (from) => set({ from }),
    setTo: (to) => set({ to }),
    setGlobalData: (globalData) => set({ globalData }),

}));

