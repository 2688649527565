export const Android = ({ size = 26, ...props }) => {
	return (
		<svg
			width={size}
			height='22'
			viewBox='0 0 26 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				d='M18.3914 8.54459L20.2222 5.37366C20.2726 5.28615 20.2862 5.18222 20.2601 5.08468C20.234 4.98714 20.1702 4.90395 20.0828 4.85336C19.9953 4.80297 19.8914 4.78936 19.7938 4.81549C19.6963 4.84163 19.6131 4.90539 19.5625 4.99279L17.7087 8.20387C16.291 7.55689 14.6989 7.19654 13 7.19654C11.3011 7.19654 9.70898 7.55689 8.29136 8.20387L6.43758 4.99279C6.38697 4.90538 6.30377 4.84162 6.20621 4.81549C6.10866 4.78935 6.00472 4.80297 5.91719 4.85336C5.82974 4.9039 5.76593 4.98709 5.73979 5.08465C5.71365 5.18221 5.72731 5.28616 5.77777 5.37366L7.60863 8.54459C4.46483 10.2545 2.3146 13.4373 2 17.1976H24C23.6851 13.4373 21.5349 10.2545 18.3914 8.54459M18.0628 14.0629C17.5576 14.0629 17.1467 13.6517 17.1467 13.1465C17.1467 12.6414 17.5577 12.2305 18.0628 12.2305C18.5679 12.2305 18.9788 12.6415 18.9788 13.1465C18.9789 13.6517 18.5679 14.0629 18.0628 14.0629ZM7.93725 14.0629C7.43208 14.0629 7.02123 13.6517 7.02123 13.1465C7.02123 12.6414 7.43208 12.2305 7.93725 12.2305C8.44243 12.2305 8.85327 12.6415 8.85327 13.1465C8.85327 13.6517 8.44233 14.0629 7.93725 14.0629Z'
				stroke='black'
				strokeWidth='2'
			/>
		</svg>
	);
};
