import { Table,Typography ,Pagination } from "antd";
import "../user-activity/style.less";
import { useReports, useApp } from "../../store";
import { useQuery } from "react-query";
import Layout from "antd/lib/layout/layout";
import { getOrdersDaily, pageSize } from "../../api";
import { Time } from "./activity";
import {useState, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs'

const DailyTable = () => {
    const { setIslogedIn } = useApp();
    const [page, setPage]= useState(1)
    const [fromDaily, setDailyFrom] = useState("");
	const [toDaily, setDailyTo] = useState("");
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
	  if (searchParams.get("page_num")) {
		setPage(searchParams.get("page_num"));
	  } else {
		setPage(1);
	  }
	}, [searchParams.get("page_num")]);

  const { data, isLoading } = useQuery(["salesDaily", fromDaily , toDaily, page],
   ({ queryKey }) => getOrdersDaily(queryKey[1], queryKey[2], queryKey[3]),
       {
      retry: 1,
  });

const {Text } =Typography

const columns = [
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
		render: (text) => <Text>{dayjs(text).format("YYYY-MM-DD")}</Text>,
	},
	{
		title: "Gross Orders",
		dataIndex: "gross_orders",
		key: "gross_orders",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "First Order",
		dataIndex: "first_order",
		key: "first_order",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Internal Orders",
		dataIndex: "internal_orders",
		key: "internal_orders",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "External Orders",
		dataIndex: "external_orders",
		key: "external_orders",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Net Order",
		dataIndex: "net_orders",
		key: "net_orders",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Organic Orders",
		key: "organic_orders",
		dataIndex: "organic_orders",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Orders .Discounts",
		key: "orders_with_discounts",
		dataIndex: "orders_with_discounts",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Orders .Vouchers ",
		key: "orders_with_vouchers",
		dataIndex: "orders_with_vouchers",
		render: (text) => <Text>{text + "%"}</Text>,
	},
	{
		title: "Voucher Spending",
		key: "voucher_spending",
		dataIndex: "voucher_spending",
		render: (text) =>
			(text !== null && <Text>{text}</Text>) || (
				<small>No voucher spending</small>
			),
	},
	{
		title: "Acquired Cost",
		key: "acquired_cost",
		dataIndex: "acquired_cost",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Android",
		key: "android",
		dataIndex: "android",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "IOS",
		key: "ios",
		dataIndex: "ios",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "OTHER",
		key: "other",
		dataIndex: "other",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "NMV",
		key: "nmv",
		dataIndex: "nmv",
		render: (text) =>
			(text !== null && <Text>{text}</Text>) || <small> no nmv</small>,
	},
	{
		title: "Delivery Fee",
		key: "delivery_fee",
		dataIndex: "delivery_fee",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Baly Commission",
		key: "baly_comission",
		dataIndex: "baly_comission",
		render: (text) =>
			(text !== null && <Text>{text}</Text>) || <small> No Commission</small>,
	},
	{
		title: "Fully Paid",
		key: "fully_paid",
		dataIndex: "fully_paid",
		render: (text) => <Text>{text}</Text>,
	},
	{
		title: "Discount Share",
		key: "discount_share",
		dataIndex: "discount_share",
		render: (text) => <Text>{text}</Text>,
	},
];



useEffect(() => {
    if (data && data.err === String("unauthorized")) {
      localStorage.clear();
      setIslogedIn(false);
    }
  }, [data]);

    return (
			<Layout className='table-container' style={{ height: "100vh" }}>
				<div style={{ width: "30%", margin: "8px 0px" }}>
					<Time
						from={fromDaily}
						to={toDaily}
						setFrom={setDailyFrom}
						setTo={setDailyTo}
						hasTitle={false}
						showTime={false}
					/>
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data?.data?.reports || []}
					scroll={{
						x: 1300,
					}}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={(val) =>{
						searchParams.set("page_num", val);
						setSearchParams(searchParams);
						setPage(val);
					  }}
					current={Number(searchParams.get("page_num")) || page}
					defaultPageSize={pageSize}
					total={data && data?.data?.total}
					showSizeChanger={false}
				/>
			</Layout>
		);
}

export default DailyTable;
