import {
    Form,
    Input,
    Button,
    Checkbox,
    Card,
    Space,
    Typography,
    Divider,
    Alert,
    message,
} from "antd";
import { useApp } from "../../store";
import { useNavigate, Navigate } from "react-router-dom";
import { login } from "../../api";
import { useState } from "react";
import balyLogo from "../../assets/baly-logo.png";

const { Title } = Typography;

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isLogedIn, setIslogedIn, setUser } = useApp();

    const onFinish = (values) => {
        setIsLoading(true);
        login(values, (res, err) => {
            if (!err) {
                if (res.success) {
                    setIsLoading(false);

                    localStorage.clear();
                    localStorage.setItem("bo_token", res.data.token);
                    localStorage.setItem(
                        "bo_refresh_token",
                        res.data.refreshToken
                    );
                    localStorage.setItem(
                        "bo_user",
                        JSON.stringify(res.data.user)
                    );

                    setIslogedIn(true);
                    setUser(res.data.user);
                    navigate("/");
                } else {
                    setIsLoading(false);
                    message.error(res.msg);
                    setError(res.msg);
                }
            } else {
                console.log(err);
                setIsLoading(false);
                message.error(err.message);
                setError("Check the network and try again !");
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return isLogedIn ? (
        <Navigate to="/" />
    ) : (
            <div className="login-page">
                <div className="center">
                    <Card
                        className={"login-card"}
                        headStyle={{ padding: "0px 40px" }}
                        bodyStyle={{ padding: "20px 40px" }}
                        title={
                            <Space align="center">
                                <img src={balyLogo} alt="Baly-logo"/>
                                <Title
                                    style={{ margin: 0, marginTop: 10 }}
                                    level={5}
                                >
                                    Baly Dashboard
                                </Title>
                            </Space>
                        }
                        style={{ width: 400, borderRadius: "20px" }}
                    >
                        {error ? (
                            <Alert
                                style={{ marginBottom: 10 }}
                                description={error}
                                type="error"
                                closable
                            />
                        ) : null}

                        <Form
                            layout={"vertical"}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your username!",
                                    },
                                ]}
                            >
                                <Input className="login-inputs" placeholder="Someone@baly.iq" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                ]}
                            >
                                <Input.Password className="login-inputs" />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <Divider />

                            <Form.Item>
                                <Button
                                    loading={isLoading}
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
    );
};

export default Login;
