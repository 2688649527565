import React from 'react';
import MapGL, { Source, Layer } from 'react-map-gl';
import { heatmapLayer } from './MapStyle';


// @ts-ignore
import mapboxgl from "mapbox-gl";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; /* eslint import/no-webpack-loader-syntax: off */



const MAPBOX_TOKEN = 'pk.eyJ1IjoibXVydGFkaGEwMDk0IiwiYSI6ImNremlvcGg4NDNwYTAydm45YXRndHpyNWMifQ._tzZQZJImmEO-_3jQ_raGA'; // Set your mapbox token here
export default function Map({ orders }) {
    return (
        <>
            <MapGL
                initialViewState={{
                    latitude: 33.3152,
                    longitude: 44.3661,
                    zoom: 10,
                }}
                mapStyle="mapbox://styles/murtadha0094/ckg1dmwkf04nd19ov7phj0c0y"
                // mapStyle="mapbox://styles/mapbox/dark-v9"
                mapboxAccessToken={MAPBOX_TOKEN}
            >
                {orders && (
                    <Source type="geojson" data={orders}>
                        <Layer {...heatmapLayer} />
                    </Source>
                )}
            </MapGL>
        </>
    );
}