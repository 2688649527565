import {Typography ,Divider}  from "antd";
import "./style.less"
const {Text} = Typography; 
const OrdersActivity = ({
    text,
    value,
    icon,
    textColor,
    customColor,
    wrapperBgColor,
    bgColor
}) => {
    return (
			<div className='component-container' style={{ backgroundColor: bgColor }}>
				<div
					className='icon-wrapper'
					style={{ backgroundColor: wrapperBgColor }}>
					{icon}
				</div>
				<Text className='activity-title' style={{ color: textColor }}>
					{text}
				</Text>
				<Text className='activity-value' style={{ color: customColor }}>
					{value}
				</Text>
			</div>
		);
};

export default OrdersActivity;
