export const Ios = ({ size = 19, ...props }) => {
	return (
		<svg
			width={size}
			height='22'
			viewBox='0 0 19 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				d='M17.7351 16.5946C17.087 16.2118 16.5216 15.6612 16.033 14.9816C15.2811 13.9359 14.9038 12.7482 14.9038 11.4495C14.9038 10.2411 15.2668 9.12716 15.9753 8.13346C16.2145 7.79577 16.529 7.44673 16.9068 7.08688C16.722 6.91842 16.5429 6.77728 16.37 6.66119L17.7351 16.5946ZM17.7351 16.5946C17.4267 17.3194 17.017 18.0543 16.5005 18.7992L16.5005 18.7992L16.495 18.8073C15.3641 20.4728 14.4566 20.9603 13.8169 20.9603C13.5429 20.9603 13.037 20.8653 12.2381 20.5935C11.3912 20.2888 10.5886 20.1142 9.84781 20.1142C9.12592 20.1142 8.35115 20.3015 7.54368 20.6204L7.54366 20.6203L7.53411 20.6242C6.84401 20.905 6.3844 21 6.11055 21C5.3105 21 4.20094 20.315 2.8673 18.0693C1.5992 15.8856 1 13.8421 1 11.9255C1 10.0926 1.46504 8.68025 2.32063 7.60736C3.16885 6.56089 4.17985 6.07692 5.41493 6.07692C5.94566 6.07692 6.65407 6.18585 7.56095 6.43692C8.0445 6.57211 8.46016 6.67735 8.80278 6.74955C9.12823 6.81812 9.44728 6.87019 9.71141 6.87019C10.2975 6.87019 11.0818 6.67049 11.9748 6.37047C12.8456 6.08953 13.5201 5.97115 14.0215 5.97115C14.9159 5.97115 15.6883 6.20443 16.3695 6.66089L17.7351 16.5946ZM11.5115 3.45985C11.1118 3.84727 10.7603 4.0628 10.4617 4.16658C10.6051 3.55462 10.8513 3.01559 11.1927 2.53912L11.1938 2.53754C11.5163 2.08551 12.0169 1.68505 12.7527 1.3709C12.7027 1.55831 12.6357 1.76019 12.5494 1.97725C12.3344 2.49581 11.9957 2.99048 11.5115 3.45985Z'
				stroke='black'
				strokeWidth='2'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
