import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "../App.less";
import {useApp} from "../store";

export default function AppHeader() {
    let location = useLocation();
    let navigate = useNavigate();
    const { setIslogedIn, isLogedIn, setUser } = useApp();
    const [current, setCurrent] = useState(null);

  useEffect(() => {
    setCurrent(location.pathname.substr(1));
  }, [location]);

     const logedOut = () => {
         if (isLogedIn){
            setIslogedIn(false);
            localStorage.clear("bo_token");
            setUser(null);
            navigate("/login")
         }
     };

    return (
			<Menu
				mode='horizontal'
				onClick={(e) => {
					navigate(`${e.key}`);
					setCurrent(e.key);
				}}
				selectedKeys={[current]} >
				<Menu.Item key='' >Hourly Orders</Menu.Item>
				<Menu.Item key='hourly'>Hourly table</Menu.Item>
				<Menu.Item key='daily'>Daily Orders</Menu.Item>

            <Menu.Item key="login" style={{left:"90%", right:"auto",  position:"absolute"}}>
                {isLogedIn ? (
                    <a
                        className="logout-btn"
                        onClick={logedOut}
                        color="white"
                    >
                        <span style={{ color: "#FF2B5E" }}> Log out </span>
                    </a>
                ) : null}
            </Menu.Item>
        </Menu>
    );
}
