export const ActivityContainer = ({
    list=[],
    icon,
    // hasDivider,
    ...props
}) => {
    return (
        <div className="other-container" { ...props}>
            {icon}
           {list}
          {/* {hasDivider ? <Divider style={{margin:"2px 0px"}}/> :null}  */}
        </div>
    );
};

